import React, {Fragment} from "react";
import Helmet from "react-helmet";
import Header from "../../components/Header";
import Tabbar from "../../components/Tabbar";
import {ReactComponent as VK} from "../../assets/img/icons/social/vk.svg";
import {ReactComponent as OK} from "../../assets/img/icons/social/ok.svg";
import {ReactComponent as Telegram} from "../../assets/img/icons/social/telegram.svg";
import {ReactComponent as Zen} from "../../assets/img/icons/social/zen.svg";

const MobileSocial = () => {
    const Links = [
        {icon: <VK/>, link: "https://vk.com/sovfin"},
        {icon: <OK/>, link: "https://ok.ru/group/68464343121982"},
        {icon: <Telegram/>, link: "https://t.me/sovfin"},
        {
            icon: <Zen/>,
            link: "https://zen.yandex.ru/id/612be26a5ef9ff13f6daf5d0",
        },
    ];

    return (
        <Fragment>
            <Helmet title="Sovfin Соцсети"/>
            <Header>Соцсети</Header>

            <main className="main mobile-soc">
                <div className="container">
                    <div className="socials socials-footer">
                        <p className="socials-footer__title">
                            Мы в социальных
                            <br/> сетях
                        </p>

                        <ul className="socials-footer-list">
                            {Links.map((el,key) => {
                                return (
                                    <li key={key} className="socials-footer-list__item">
                                        <a
                                            href={el.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="socials-footer-list__link"
                                        >
                                            {el.icon}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </main>

            <Tabbar/>
        </Fragment>
    );
};

export default MobileSocial;
