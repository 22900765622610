import React from "react";

const Header = ({
  left = false,
  right = false,
  children = "",
  cssName = "",
}) => {
  return (
    <div className={`mobile-title ${cssName}`}>
      {left}
      {right}
      {!left && !right && <p className="mobile-title__text">{children}</p>}
    </div>
  );
};

export default Header;
