import { atom } from "recoil";

export const NewsState = atom({
  key: "NewsState",
  default: null,
});

export const PageState = atom({
  key: "PageState",
  default: {
    hasMore: true,
    page: 1,
    maxPage: 1,
  },
});

export const CacheState = atom({
  key: "CacheState",
  default: [],
});

export const LoadingState = atom({
  key: "LoadingState",
  default: true,
});

export const modalState = atom({
  key: "modalState",
  default: {
    active: false,
    infoPage: {},
  },
});
