import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as News } from "../assets/img/icons/tabbar/news.svg";
import { ReactComponent as World } from "../assets/img/icons/tabbar/world.svg";

const Tabbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="mobile-menu">
      <span
        onClick={() => navigate("/")}
        className={`mobile-menu__link ${
          (location.pathname === "/" ||
            location.pathname.substr(0, 5) === "/news") &&
          "active"
        }`}
      >
        <News />
      </span>

      <span
        onClick={() => navigate("/mobile-social")}
        className={`mobile-menu__link ${
          location.pathname === "/mobile-social" && "active"
        }`}
      >
        <World />
      </span>
    </div>
  );
};

export default Tabbar;
