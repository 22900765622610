import React from "react";
import { ReactComponent as VK } from "../assets/img/icons/social/vkShare.svg";
import { ReactComponent as TG } from "../assets/img/icons/social/tgShare.svg";
import { ReactComponent as OK } from "../assets/img/icons/social/okShare.svg";

const ShareNews = ({ info }) => {
  return (
    <div className="article-share">
      <p className="article-share__text">Поделиться новостью:</p>

      <a
        href={`https://vk.com/share.php?url=https://news.sovfin.ru/news/${info.id}&title=${info.title}&image=${info.photo_url}`}
        target="_blank"
        rel="noreferrer"
        className="article-share__link"
      >
        <VK />
      </a>

      <a
        href={`https://t.me/share/url?url=https://news.sovfin.ru/news/${info.id}&text=${info.title}`}
        target="_blank"
        rel="noreferrer"
        className="article-share__link"
      >
        <TG />
      </a>

      <a
        href={`https://connect.ok.ru/offer?url=https://news.sovfin.ru/news/${info.id}`}
        target="_blank"
        rel="noreferrer"
        className="article-share__link"
      >
        <OK />
      </a>
    </div>
  );
};

export default ShareNews;
