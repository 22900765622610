import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { modalState } from "../store/main";

import { ReactComponent as VK } from "../assets/img/icons/social/vk.svg";
import { ReactComponent as OK } from "../assets/img/icons/social/ok.svg";
import { ReactComponent as Telegram } from "../assets/img/icons/social/telegram.svg";

const ModalShare = () => {
  const [modalStorage, setModal] = useRecoilState(modalState);
  const Links = [
    {
      icon: <VK />,
      link: `https://vk.com/share.php?url=https://news.sovfin.ru/news/${modalStorage.infoPage.id}&title=${modalStorage.infoPage.title}&image=${modalStorage.infoPage.photo_url}`,
    },
    {
      icon: <OK />,
      link: `https://connect.ok.ru/offer?url=https://news.sovfin.ru/news/${modalStorage.infoPage.id}`,
    },
    {
      icon: <Telegram />,
      link: `https://t.me/share/url?url=https://news.sovfin.ru/news/${modalStorage.infoPage.id}&text=${modalStorage.infoPage.title}`,
    },
  ];

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        if (e.target.className === "modal.active modal-share js-modal-share") {
          setModal({ ...modalStorage, active: false });
        }
      },
      false
    );
  });

  return (
    <div
      className={`modal${
        modalStorage.active ? ".active" : ""
      } modal-share js-modal-share`}
    >
      <div className="modal__content">
        <p className="title">Поделиться новостью</p>

        <ul className="modal-share-list">
          {Links.map((el) => {
            return (
              <li className="modal-share-list__item">
                <a
                  href={el.link}
                  target="_blank"
                  rel="noreferrer"
                  className="modal-share-list__link"
                >
                  {el.icon}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ModalShare;
