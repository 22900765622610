import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./panels/home/base";
import News from "./panels/home/news";
import MobileSocial from "./panels/home/mobileSocial";

const LoginAdmin = lazy(() => import("./panels/admin/login"));
const Admin = lazy(() => import("./panels/admin/base"));

function App() {
  return (
    <div className="site-wrapper">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/mobile-social" element={<MobileSocial />} />
          <Route path="/news/:newsId" element={<News />} />

          <Route
            path="/admin"
            element={
              <Suspense fallback="">
                <LoginAdmin />
              </Suspense>
            }
          />

          <Route
            path="/admin/main"
            element={
              <Suspense fallback="">
                <Admin />
              </Suspense>
            }
          />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
