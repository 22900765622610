import React, { useEffect, useCallback, Fragment } from "react";
import { useRecoilState } from "recoil";
import fetch2 from "../../functions/fetch2";

import Helmet from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../../components/Footer";
import Tabbar from "../../components/Tabbar";
import ModalShare from "../../components/ModalShare";
import Header from "../../components/Header";
import NewsBlock from "../../components/NewsBlock";
import SkeletonNewsBlocks from "../../components/SkeletonNewsBlock";
import { NewsState, LoadingState, PageState } from "../../store/main";

const Home = () => {
  const [loading, setLoading] = useRecoilState(LoadingState);
  const [news, setNews] = useRecoilState(NewsState);
  const [page, setPage] = useRecoilState(PageState);

  const getNews = useCallback(async (numberPage = false) => {
    let response = await fetch2(
      `news${numberPage ? `?page=${numberPage + 1}` : ""}`,
      "GET"
    );
    if (response.response) {
      await setPage({
        hasMore: numberPage + 1 !== response.pages,
        page: numberPage ? page.page + 1 : 1,
        maxPage: response.pages,
      });

      if (numberPage) {
        let arr = news.concat(response.news);
        setNews(arr);
      } else {
        setNews(response.news);
      }

      setLoading(false);
    }
  }, [page.page, news, setLoading, setNews, setPage])

  useEffect(() => {
    if (news === null) {
      getNews();
    } else {
      setLoading(false);
    }
  }, [news, getNews, setLoading]);

  return (
    <Fragment>
      <Helmet title="Sovfin Новости" />
      <Header>Новости</Header>

      <main className="main news">
        <div className="container">
          <h1 className="title news__title">Новости</h1>
          <InfiniteScroll
            dataLength={news === null ? 6 : news.length}
            next={() => getNews(page.page)}
            hasMore={page.hasMore}
            className={"news-list"}
          >
            {loading ? (
              <SkeletonNewsBlocks />
            ) : (
              news.map((el, key) => {
                return (
                  <NewsBlock key={key} data={el} className="news-list__item" />
                );
              })
            )}
          </InfiniteScroll>
        </div>
      </main>

      <Footer />
      <Tabbar />
      <ModalShare />
    </Fragment>
  );
};

export default Home;
