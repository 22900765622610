import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { modalState } from "../store/main";
import returnDate from "../functions/returnDate";

const NewsBlock = ({ data, className }) => {
  const [, setModal] = useRecoilState(modalState);
  const navigate = useNavigate();

  return (
    <div
      className={`${className} news-item`}
      onClick={() => {
        navigate(`/news/${data.id}`);
        window.scrollTo(0, 0);
      }}
    >
      <img src={data.photo_url} alt="img" className="news-item__img" />

      <div className="news-item__content">
        <div className="news-item__date news-item__date--mobile">
          {returnDate(data.date)}
        </div>

        <span className="news-item__title">{data.title}</span>

        <div className="news-item__box">
          <div className="views">{data.views}</div>
          <div className="news-item__date">{returnDate(data.date)}</div>
          {className === "news-list__item" ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setModal({ infoPage: data, active: true });
              }}
              className="news-item__mobile-share"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsBlock;
