import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonNewsBlock = () => {
  return (
    <div className="news-list__item news-item">
      <Skeleton className="news-item__img" />

      <div className="news-item__content">
        <div className="news-item__date news-item__date--mobile">
          <Skeleton />
        </div>

        <span className="news-item__title">
          <Skeleton count={2} className="mb2" />
        </span>

        <div className="news-item__box">
          <div className="views">
            <Skeleton width={"100px"} />
          </div>

          <div className="news-item__date">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonNewsBlocks = () => {
  return (
    <>
      <SkeletonNewsBlock />
      <SkeletonNewsBlock />
      <SkeletonNewsBlock />
      <SkeletonNewsBlock />
      <SkeletonNewsBlock />
      <SkeletonNewsBlock />
    </>
  );
};

export default SkeletonNewsBlocks;
