export default function returnDate(unixtime) {
  let date = new Date(unixtime * 1000);
  let arrMonth = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  return `
		${date.getDate()}
		${arrMonth[date.getMonth()]}
		${date.getFullYear()}
	`;
}
