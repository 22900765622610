/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { ReactComponent as VK } from "../assets/img/icons/social/vk.svg";
import { ReactComponent as OK } from "../assets/img/icons/social/ok.svg";
import { ReactComponent as Telegram } from "../assets/img/icons/social/telegram.svg";
import { ReactComponent as Zen } from "../assets/img/icons/social/zen.svg";

const Footer = () => {
  const Links = [
    { icon: <VK />, link: "https://vk.com/sovfin" },
    { icon: <OK />, link: "https://ok.ru/group/68464343121982" },
    { icon: <Telegram />, link: "https://t.me/sovfin" },
    {
      icon: <Zen />,
      link: "https://zen.yandex.ru/id/612be26a5ef9ff13f6daf5d0",
    },
  ];

  return (
    <footer className="footer footer-socials">
      <div className="container">
        <div className="socials socials-footer">
          <p className="socials-footer__title">Мы в социальных сетях</p>
          <ul className="socials-footer-list">
            {Links.map((el) => {
              return (
                <div className="socials-footer-list__item">
                  <a
                    href={el.link}
                    target="_blank"
                    className="socials-footer-list__link"
                    rel="noreferrer"
                  >
                    {el.icon}
                  </a>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
