import React, {useState, useCallback, useEffect, Fragment} from "react";
import {useRecoilState} from "recoil";
import {useNavigate, useParams} from "react-router-dom";

import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import {ReactComponent as Back} from "../../assets/img/icons/back.svg";
import Header from "../../components/Header";
import ShareNews from "../../components/ShareNews";
import NewsBlock from "../../components/NewsBlock";
import Footer from "../../components/Footer";
import Tabbar from "../../components/Tabbar";
import ModalShare from "../../components/ModalShare";

import fetch2 from "../../functions/fetch2";
import returnDate from "../../functions/returnDate";
import randomElArr from "../../functions/randomElArr";
import {NewsState, modalState, CacheState} from "../../store/main";

const News = () => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(null);
    const [random, setRandom] = useState([]);
    const [, setModal] = useRecoilState(modalState);
    const [cache, setCache] = useRecoilState(CacheState)
    const [news, setNews] = useRecoilState(NewsState);
    const navigate = useNavigate();
    const params = useParams();

    const getInfo = useCallback(async () => {
        if (cache[params.newsId] === undefined) {
            let response = await fetch2(`news?id=${params.newsId}`, "GET");
            if (response.response) {
                let arr = [...cache]
                arr[params.newsId] = response.info

                setCache(arr)
                setInfo(response.info);
                setLoading(false);
            }

            if (news === null) {
                response = await fetch2("news", "GET");
                setNews(response.news);
            }

            setRandom(randomElArr(news === null ? response.news : news, 3));
        } else {
            setInfo(cache[params.newsId]);
            setRandom(randomElArr(news, 3));
            setLoading(false);
        }
    }, [cache, news, params.newsId, setCache, setNews])

    useEffect(() => {
        setLoading(true);
        getInfo();
    }, [params, getInfo]);

    return (
        <Fragment>
            <Helmet title={loading ? "Sovfin Статья" : info.title}/>
            <Header
                left={
                    <div onClick={() => navigate(-1)} className="mobile-title__back">
                        <Back/>
                    </div>
                }
                right={
                    <div
                        onClick={() => setModal({infoPage: info, active: true})}
                        className="news-item__mobile-share"
                    />
                }
                cssName="mobile-title--article"
            />

            <div className="container">
                <article className="article">
                    <header className="article__header">
                        <h1>{loading ? <Skeleton/> : info.title}</h1>
                    </header>

                    <div className="article__content">
                        {loading ? (
                            <Skeleton className="article__preview article__skeleton"/>
                        ) : (
                            <img src={info.photo_url} alt="" className="article__preview"/>
                        )}
                        <div className="news-item__box article__mob-info">
                            <div className="views">
                                {loading ? <Skeleton width={"80px"}/> : info.views}
                            </div>
                            <div className="news-item__date--mobile">
                                {loading ? <Skeleton width={"80px"}/> : returnDate(info.date)}
                            </div>
                        </div>

                        <p>{loading ? <Skeleton count={10}/> : info.text}</p>
                    </div>

                    <footer className="article__footer">
                        {!loading && <ShareNews info={info}/>}

                        <div className="article__info">
                            <div className="article__date">
                                {loading ? <Skeleton width={"80px"}/> : returnDate(info.date)}
                            </div>
                            <div className="views">
                                {loading ? <Skeleton width={"80px"}/> : info.views}
                            </div>
                        </div>
                    </footer>
                </article>
            </div>

            <section className="likewise">
                <div className="container">
                    <h3 className="title">Читайте также</h3>
                    <ul className="likewise-list">
                        {loading
                            ? ""
                            : random.map((el, key) => {
                                return (
                                    <NewsBlock data={el} key={key} className="likewise-list__item"/>
                                );
                            })}
                    </ul>
                </div>
            </section>

            <Footer/>
            <Tabbar/>
            <ModalShare/>
        </Fragment>
    );
};

export default News;
