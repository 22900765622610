export default async function fetch2(
  endpoint,
  method,
  params,
  isUpload = false
) {
  let headers = {
    authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
  if (!isUpload) headers["Content-Type"] = "application/json";

  const data = await fetch(`https://sovfin.nbalin.dev/v1/${endpoint}`, {
    method: method,
    headers: headers,
    body: isUpload ? params : JSON.stringify(params),
  });

  const responseData = await data.json();
  if (!responseData.response) {
    if (responseData.error.code !== 7) return responseData;

    let response = await authFetch();
    if (response.response) {
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("refresh_token", response.refresh_token);

      return await fetch2(endpoint, method, params, isUpload);
    } else {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
  } else {
    return responseData;
  }
}

async function authFetch() {
  const data = await fetch(`https://sovfin.nbalin.dev/v1/profile/tokens`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      refresh: localStorage.getItem("refresh_token"),
    },
  });

  return await data.json();
}
