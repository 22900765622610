export default function randomElArr(arr, length) {
  return first(shuffle(arr), length);
}

function first(arr, length) {
  return arr.slice(0, length);
}

function shuffle(arr) {
  let result = [];
  let arr2 = [...arr];

  while (arr2.length > 0) {
    let random = getRandomInt(0, arr2.length - 1);
    let elem = arr2.splice(random, 1)[0];
    result.push(elem);
  }

  return result;
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
